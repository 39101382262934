import React from 'react';
import "./NotFound.css"
import {useHistory} from "react-router-dom";

const NotFound = () => {
    const history = useHistory();
    return (
        <div className={"body"}>
            <div className={"position-absolute top-50 start-50 translate-middle"}>
                <h1 className={"error-heading"}>404</h1>
                <p className={"error-text"}>Oops! Something is wrong.</p>
                <button className="error-button" onClick={() => {history.goBack()}}><i className="icon-home"></i> Go back in initial page, is better.</button>
            </div>
        </div>
    );
};

export default NotFound;