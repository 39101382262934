import React from "react";
import './SideNavbar.css'

const SideNavbar = () => {
    return (
        <nav className="social">
            <ul className="social-list">
                <li>
                    <a href="https://wa.me/+447775627393">
                        <i className="fab fa-whatsapp"></i>
                        <div className="whatsappQR"></div>
                    </a>
                </li>
                <li><a href="https://www.instagram.com/brightminderuk/" target="blank"><i className="fab fa-instagram"></i></a></li>
                <li><a href="#" target="blank"><i className="fab fa-linkedin"></i></a></li>
            </ul>
        </nav>
    )
}

export default SideNavbar;