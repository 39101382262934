import React from "react";
import './home.css'
// import Navbar from "../Navbar/Navbar";
import ImageSlider from "./imageslider/ImageSlider";
import HomeAbout from "./home_about/HomeAbout";
import HowWeWork from "./home_about/HowWeWork";
import Qualities from "./Qualities/Qualities";

const Homepage = () => {
    return (
        <div className="content">
            <div className="section1">
                <ImageSlider/>
            </div>
            {/*<div className="section2">*/}
            {/*    <HomeAbout/>*/}
            {/*</div>*/}
            {/*<div className="section3">*/}
            {/*    <HowWeWork/>*/}
            {/*</div>*/}
            {/*<div className="section4">*/}
            {/*    <Qualities/>*/}
            {/*</div>*/}
        </div>
    );
}

export default Homepage;