import React from "react";
import './Services.css'
import {Link} from "react-router-dom";

const Services = () => {
    const services_cards_content = [
        {
            "heading": "People & Workforce",
            "explanation": "Empowering your people, driving your success.",
            "url": "/people-and-workforce"
        },
        {
            "heading": "Engagement Fees",
            "explanation": "Transparent pricing, no hidden costs.",
            "url": "/engagement-fees"
        },
        // {
        //     "heading": "Terms of Business",
        //     "explanation": "Building strong foundations for long-term success.",
        //     "url": "/terms-of-business"
        // },
        {
            "heading": "Frequently Asked Questions",
            "explanation": "Your questions answered, your concerns addressed.",
            "url": "/faq"
        }
    ]

    return (
        <div className="services-content-container">
            <div className="services-main-content"></div>
            <div className="services-info">
                <h1>Services<span className="bColor">_</span></h1>
                <p>
                    Bright Minder helps clients create value and service solutions that provide trust through assurance and
                    help clients transform, grow, and operate.
                </p>
                <p>
                    We help businesses and families develop skills and grow by finding the right talent to meet their needs,
                    from entry-level to executive positions. Our passion is in helping clients achieve their recruitment or
                    operational goals.
                </p>
            </div>
            <div className="services-section">
                <div className="services-content-section">
                    <div className="card-deck">
                        {
                            services_cards_content.map(
                                (item) => {
                                    return (
                                        <div className="card" key={item.heading}>
                                            <div className="card-content position-relative top-50 start-50 translate-middle p-5">
                                                <h3 className="card-heading">
                                                    {item.heading}
                                                </h3>
                                                <p>
                                                    {item.explanation}
                                                </p>
                                                <Link to={item.url}>Explore</Link>
                                            </div>
                                        </div>
                                    )
                                }
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Services;