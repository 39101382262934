import React from "react";
import './Client.css'
import {Link} from "react-router-dom";

const Client = () => {

    const countryData = [
        { country_code: "+86", country: "China" },
        { country_code: "+91", country: "India" },
        { country_code: "+1", country: "United States" },
        { country_code: "+62", country: "Indonesia" },
        { country_code: "+92", country: "Pakistan" },
        { country_code: "+234", country: "Nigeria" },
        { country_code: "+880", country: "Bangladesh" },
        { country_code: "+7", country: "Russia" },
        { country_code: "+81", country: "Japan" },
        { country_code: "+52", country: "Mexico" },
        { country_code: "+55", country: "Brazil" },
        { country_code: "+251", country: "Ethiopia" },
        { country_code: "+63", country: "Philippines" },
        { country_code: "+20", country: "Egypt" },
        { country_code: "+84", country: "Vietnam" },
        { country_code: "+243", country: "Democratic Republic of the Congo" },
        { country_code: "+98", country: "Iran" },
        { country_code: "+90", country: "Turkey" },
        { country_code: "+66", country: "Thailand" },
        { country_code: "+44", country: "United Kingdom" },
        { country_code: "+33", country: "France" },
        { country_code: "+49", country: "Germany" },
        { country_code: "+39", country: "Italy" },
        { country_code: "+54", country: "Argentina" },
        { country_code: "+255", country: "Tanzania" },
        { country_code: "+27", country: "South Africa" },
        { country_code: "+95", country: "Myanmar" },
        { country_code: "+254", country: "Kenya" },
        { country_code: "+57", country: "Colombia" },
        { country_code: "+34", country: "Spain" },
        { country_code: "+48", country: "Poland" },
        { country_code: "+249", country: "Sudan" },
        { country_code: "+256", country: "Uganda" },
        { country_code: "+213", country: "Algeria" },
        { country_code: "+380", country: "Ukraine" },
        { country_code: "+964", country: "Iraq" },
        { country_code: "+93", country: "Afghanistan" },
        { country_code: "+977", country: "Nepal" },
        { country_code: "+58", country: "Venezuela" },
        { country_code: "+7", country: "Kazakhstan" },
        { country_code: "+966", country: "Saudi Arabia" },
        { country_code: "+51", country: "Peru" },
        { country_code: "+60", country: "Malaysia" },
        { country_code: "+998", country: "Uzbekistan" },
        { country_code: "+258", country: "Mozambique" },
        { country_code: "+233", country: "Ghana" },
        { country_code: "+244", country: "Angola" },
        { country_code: "+967", country: "Yemen" },
        { country_code: "+225", country: "Côte d'Ivoire" },
        { country_code: "+82", country: "South Korea" },
        { country_code: "+502", country: "Guatemala" },
        { country_code: "+56", country: "Chile" },
        { country_code: "+593", country: "Ecuador" },
        { country_code: "+53", country: "Cuba" },
        { country_code: "+257", country: "Burundi" },
        { country_code: "+591", country: "Bolivia" },
        { country_code: "+509", country: "Haiti" },
        { country_code: "+229", country: "Benin" }
    ];

    return (
        <div className="recruit-content-container">
            <div className="recruit-main-content"></div>
            <div className="recruit-info w-50 position-absolute start-50 top-0 translate-middle-x">
                <h1><span className="bColor">_</span>Register With Us</h1>
                <p>
                    We understand that every family is different, every child has individual needs, and every candidate
                    can bring different strengths into the home. We have over 30 years’ experience in placing maternity
                    nurses, permanent nannies, and governesses across London and worldwide with a wide selection of
                    welcoming families.
                </p>
                <p>
                    Create your family profile with us, and one of our consultants will be in touch to understand
                    exactly
                    what you’re looking for for your children, and how we can help. We work tirelessly to ensure the
                    perfect match, and are happy to answer any questions you might have, or discuss any uncertainties.
                </p>
                <div className="about-buttons">
                    <Link to="/client-journey" className="about-btn">View Journey</Link>
                </div>
            </div>
            <div id="recruitment" className="client-section">
                <h1 className="recruit-heading p-2">
                    Client Form<span className="bColor">_</span>
                </h1>
                <div className="recruitment-section">
                    <div className="candidate-info">
                        <div className="candidate-container mx-auto">
                            <div className="row input-container">
                                <div className="col-md-6 col-sm-2">
                                    <div className="styled-input wide">
                                        <input type="text" required />
                                        <label>Client Firstname</label>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-2">
                                    <div className="styled-input wide">
                                        <input type="text" required />
                                        <label>Client Surname</label>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-2">
                                    <div className="styled-input">
                                        <input type="text" required />
                                        <label>Email Address</label>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="styled-input" style={{float: "right"}}>
                                        <input type="text" required />
                                        <label>Location (City/Country)</label>
                                    </div>
                                </div>
                                <div className="col-xs-12" style={{height: 20}}>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <label style={{marginBottom: 0, paddingBottom: 0}}>Telephone Country Code</label>
                                    <div className="styled-input wide">
                                        <select name="country_codes" id="country_codes" className="select-field">
                                            {
                                                countryData.map(item => {
                                                    let country_code = item.country_code
                                                    return <option value={country_code} key={country_code}>{item.country} ({country_code})</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="styled-input" style={{float: "right"}}>
                                        <input type="text" required />
                                        <label>Telephone Number</label>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-2">
                                    <div className="styled-input">
                                        <input type="text" required />
                                        <label>Confirm Telephone Number</label>
                                    </div>
                                </div>
                                <div className="col-xs-12" style={{height: 20}}>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="styled-input" style={{float: "right"}}>
                                        <input type="text" required />
                                        <label>Client/Family Nationality</label>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <label style={{padding: -100, marginTop: 10}}>Preferred method of contact</label>
                                    <div className="styled-input wide" style={{padding: "-15px -15px", margin: -5}}>
                                        <input type="checkbox" id="telephone" name="telephone" value="telephone" width={20}/>Telephone
                                        <br/>
                                        <input type="checkbox" id="email" name="email" value="email" />Email
                                    </div>
                                </div>
                                <div className="col-xs-12">
                                    <div className="styled-input wide">
                                        <textarea required></textarea>
                                        <label className="textarea">Family Requirements/Additional Information</label>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <label style={{marginBottom: 0, paddingBottom: 0}}>How did you hear about Bright Minder?</label>
                                    <div className="styled-input wide">
                                        <select name="referrals" id="referrals" className="select-field">
                                            <option value="Google">Google</option>
                                            <option value="Social media">Social media</option>
                                            <option value="Referral">Referral (friend, family, colleague)</option>
                                            <option value="Other">Other</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-10 col-sm-12 py-4">
                                    <input type="checkbox" id="consent" name="consent" value="consent" style={{marginBottom: "20px"}}/>
                                    <label style={{width: "90%"}}>
                                        I consent to my submitted data being collected and stored to allow
                                        Bright Minder to deal with my enquiry. For information about how we will use and
                                        protect your personal data please view our <Link to="#">privacy policy</Link>.
                                    </label>
                                </div>
                                <div className="col-xs-12">
                                    <div className="btn-lrg submit-btn">Send Message</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Client;