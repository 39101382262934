import React from 'react';
import './navbar.css';
import {Link} from 'react-router-dom'
import Insights from "./Insights/InsightsNav";
import ServicesNav from "./Services/ServicesNav";
import RecruitmentNav from "./Recruitment/RecruitmentNav";

const Navbar = () => {
  return (
        <nav className="navbar d-flex flex-column navbar-expand-lg w-100">
            <div className="grad-bar"></div>
            <div className="container-fluid">
                <Link className="navbar-brand" to="/">
                  <img src={process.env.PUBLIC_URL + "/logo.png"} width={"50px"} alt=""/> Bright Minder
                </Link>

                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
                  <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="collapsibleNavbar">
                    <ul className="navbar-nav w-100">
                        {/*<li className="nav-item dropdown">*/}
                        {/*    <div className="nav-link">Insights</div>*/}
                        {/*    <ul className="custom-dropdown-menu">*/}
                        {/*        <Insights/>*/}
                        {/*    </ul>*/}
                        {/*</li>*/}
                        <li className="nav-item">
                            <Link className="nav-link" to="/about">About us</Link>
                        </li>
                        <li className="nav-item dropdown">
                            <div className="nav-link">Services</div>
                            <ul className="custom-dropdown-menu">
                                <ServicesNav/>
                            </ul>
                        </li>
                        <li className="nav-item dropdown">
                            <div className="nav-link">Recruitment</div>
                            <ul className="custom-dropdown-menu">
                                <RecruitmentNav/>
                            </ul>
                        </li>
                        {/*<li className="nav-item">*/}
                        {/*    <Link className="nav-link" to="/consultancy">*/}
                        {/*        Consultancy*/}
                        {/*    </Link>*/}
                        {/*</li>*/}
                        {/*<li className="nav-item">*/}
                        {/*    <Link className="nav-link" to="/faq">FAQ</Link>*/}
                        {/*</li>*/}
                        <li className="nav-item">
                            <Link className="nav-link" to="/contact">Contact Us</Link>
                        </li>
                        {/*<li className="nav-item dropdown ms-auto">*/}
                        {/*    <Link className="nav-link" to="/portal/login">Login</Link>*/}
                        {/*</li>*/}
                    </ul>
                </div>
            </div>
        </nav>
  )
}

export default Navbar;