import React from "react";
import './Recruitment.css'
import {Link} from "react-router-dom";

const Recruitment = () => {
    return (
        <div className="recruit-content-container">
            <div className="recruit-main-content"></div>
            <div className="recruit-info w-50 position-absolute start-50 top-0 translate-middle-x">
                <h1><span className="bColor">_</span>Recruit With Us</h1>
                <p>
                    We understand that every family is different, every child has individual needs, and every candidate
                    can bring different strengths into the home. We have over 30 years’ experience in placing maternity
                    nurses, permanent nannies, and governesses across London and worldwide with a wide selection of
                    welcoming families.
                </p>
                <p>
                    Create your family profile with us, and one of our consultants will be in touch to understand exactly
                    what you’re looking for for your children, and how we can help. We work tirelessly to ensure the
                    perfect match, and are happy to answer any questions you might have, or discuss any uncertainties.
                </p>
            </div>
            <div id="recruitment" className="recruitment-section">
                <h1 className="how-we-work-heading p-2">
                    Register With Us_
                </h1>
                <div className="how-we-work-section">
                    <div className="how-we-work-section-info">
                        <div className="how-we-work-section-info-content w-75 position-relative top-50 start-50 translate-middle">
                            <h3>Client Registration Form</h3>
                            <p>
                                We understand that every family is different, every child has individual needs, and
                                every candidate can bring different strengths into the home. We have over 30 years’
                                experience in placing maternity nurses, permanent nannies, and governesses across London
                                and worldwide with a wide selection of welcoming families.
                            </p>
                            <p>
                                Create your family profile with us, and one of our consultants will be in touch to
                                understand exactly what you’re looking for for your children, and how we can help.
                                We work tirelessly to ensure the perfect match, and are happy to answer any questions
                                you might have, or discuss any uncertainties.
                            </p>
                            <div className="about-buttons">
                                <Link to="/client-registration" className="about-btn">Register Here</Link>
                                <Link to="/client-journey" className="about-btn">View Journey</Link>
                            </div>
                        </div>
                    </div>
                    <div className="how-we-work-section-info">
                        <div className="how-we-work-section-info-content w-75 position-relative ps-5 ms-5 py-4 top-0 start-">
                            <h3>Candidate Registration Form</h3>
                            <p>
                                Our team of consultants will assess your profile and if it meets our selective requirements,
                                we will organise an interview. If your interview is successful, your profile will be
                                included in our database of registered candidates.
                            </p>
                            <div className="about-buttons">
                                <Link to="/candidate-registration" className="about-btn">Register Here</Link>
                                <Link to="/candidate-journey" className="about-btn">View Journey</Link>
                            </div>
                        </div>
                    </div>
                    {/*<div className="how-we-work-section-graphic">*/}
                    {/*    <div className="border-box position-relative top-50 start-50 translate-middle">*/}
                    {/*        <div className="border-box-inner position-relative top-75 start-75 translate-middle"></div>*/}
                    {/*        /!*<img src={imgRelativePath+`/AboutPage/about_page_interview.png`} className="position-relative top-50 start-50 translate-middle" alt="Interview Icon"/>*!/*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
                {/*<div className="how-we-work-section">*/}
                {/*    <div className="how-we-work-section-graphic">*/}
                {/*        <div className="border-box position-relative top-50 start-50 translate-middle">*/}
                {/*            <div className="border-box-inner position-relative top-75 start-75 translate-middle"></div>*/}
                {/*            /!*<img src={imgRelativePath+`/AboutPage/about_page_authenticity.png`} className="position-relative top-50 start-50 translate-middle" alt="We Check Icon"/>*!/*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <div className="how-we-work-section-info">*/}
                {/*        <div className="how-we-work-section-info-content w-75 position-relative top-50 start-50 translate-middle">*/}
                {/*            <h3>Candidate Registration Form</h3>*/}
                {/*            <p>*/}
                {/*                Our team of consultants will assess your profile and if it meets our selective requirements,*/}
                {/*                we will organise an interview. If your interview is successful, your profile will be*/}
                {/*                included in our database of registered candidates.*/}
                {/*            </p>*/}
                {/*            <div className="about-buttons">*/}
                {/*                <a href="#" className="about-btn">Download Form Here</a>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        </div>
    )
}

export default Recruitment;