import React from "react";
import {Link} from "react-router-dom";

const Consultancy = () => {
    const vidRelativePath = `${process.env.PUBLIC_URL}/Assets/Videos/`
    const imgRelativePath = `${process.env.PUBLIC_URL}/Assets/Images/`

    const people_and_workforce_cards = [
        {
            "heading": "Leadership and Management"
        },
        {
            "heading": "Contract Management"
        },
        {
            "heading": "Commercial & Procurement Strategy"
        },
        {
            "heading": "Supplier Relationship Management"
        },
        {
            "heading": "Import & Export Customs Brokerage"
        },
        {
            "heading": "Supply Chain Optimisation"
        },
        {
            "heading": "Risk Management"
        },
        {
            "heading": "Logistics Advisory"
        },
        {
            "heading": "Project Management"
        }
    ]
    const country_flags_and_details = [
        {
            "heading": "United Kingdom",
            "subheading": "Supply Chain & Operations, Customs Brokerage, Risk Management",
            "flag": imgRelativePath + "/country_flags/uk.jpg"
        },
        {
            "heading": "Kingdom of Saudi Arabia ",
            "subheading": "Supply Chain & Operations, Customs Brokerage, Warehousing & Logistics",
            "flag": imgRelativePath + "/country_flags/saudi.jpg"
        },
        {
            "heading": "United Arab Emirates",
            "subheading": "Supply Chain & Operations, Customs Brokerage, Risk Management",
            "flag": imgRelativePath + "/country_flags/uae.jpg"
        },
        {
            "heading": "Republic of South Africa",
            "subheading": "Logistics, International Customs Advisory, Risk Management",
            "flag": imgRelativePath + "/country_flags/rsa.jpg"
        },
        {
            "heading": "United States of America",
            "subheading": "Supply Chain & Operations, Customs Brokerage",
            "flag": imgRelativePath + "/country_flags/america.jpg"
        }
    ]
    return (
        <div className="services-content-container">
            <div className="services-main-content video">
                <video autoPlay loop muted>
                    <source src={vidRelativePath + "consultancy.mp4"} type="video/mp4"/>
                </video>
            </div>
            <div className="services-info">
                <h1>Consultancy<span className="bColor">_</span></h1>
                <p>
                    Welcome to Bright Minder UK, Consultancy specialising in Supply Chain & Operations, Risk Management,
                    Customs Brokerage, Advisory.
                </p>
                <p>
                    We have a strong focus on diversity, inclusivity, and cross-cultural communication. Matching our
                    clients
                    with professionals that can adjust to the local and international environment. .
                </p>
                <p>
                    As a Consultancy, we serve Clients ranging from Government & Infrastructure bodies, Retail, Technology &
                    Transportation.
                </p>
            </div>
            <div id="how-we-work" className="about-how-we-work">
                <h4 className="w-50 mx-auto my-3 py-3">Our comprehensive Consultancy expertise includes: </h4>
                <div className="card-deck people-card-deck mx-auto">
                    {
                        people_and_workforce_cards.map(
                            (item) => {
                                return (
                                    <div className="card people-cards" key={item.heading}>
                                        <div
                                            className="card-content position-relative top-50 start-50 translate-middle p-5">
                                            <h4 className="card-heading">
                                                {item.heading}
                                            </h4>
                                        </div>
                                    </div>
                                )
                            }
                        )
                    }
                </div>
                <h5 style={{width: '75%', marginTop: '30px'}} className={"mx-auto"}>
                    We offer services in the following countries (hover on the flag):
                </h5>
                <div className="main mx-auto">
                    <ul>
                        {
                            country_flags_and_details.map(item => {
                                return <li>{item.heading} <img src={item.flag} width="30px" style={{marginLeft: "10px"}}
                                                               alt=""/></li>
                            })
                        }
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Consultancy;