import React from "react";

const PeopleAndWorkforce = () => {
    const vidRelativePath = `${process.env.PUBLIC_URL}/Assets/Videos/`
    const imgRelativePath = `${process.env.PUBLIC_URL}/Assets/Images/`
    const people_and_workforce_cards = [
        {
            "heading": "Leadership and Management"
        },
        {
            "heading": "Quality Assurance"
        },
        {
            "heading": "Health and Well-being"
        },
        {
            "heading": "Curriculum Development"
        },
        {
            "heading": "Play"
        },
        {
            "heading": "Behaviour"
        },
        {
            "heading": "Children and Families"
        }
    ]

    const country_flags_and_details = [
        {
            "heading": "United Kingdom",
            "subheading": "Nanny, Governess & Nursing Agency",
            "flag": imgRelativePath + "/country_flags/uk.jpg"
        },
        {
            "heading": "Kingdom of Saudi Arabia ",
            "subheading": "Nanny, Governess & Nursing Agency",
            "flag": imgRelativePath + "/country_flags/saudi.jpg"
        },
        {
            "heading": "United Arab Emirates",
            "subheading": "Nanny, Governess & Nursing Agency",
            "flag": imgRelativePath + "/country_flags/uae.jpg"
        },
        {
            "heading": "Australia",
            "subheading": "Nanny, Governess & Nursing Agency",
            "flag": imgRelativePath + "/country_flags/australia.jpg"
        },
        {
            "heading": "Republic of South Africa",
            "subheading": "Nanny, Governess & Nursing Agency, Tourism, Import & Export Brokerage",
            "flag": imgRelativePath + "/country_flags/rsa.jpg"
        },
        {
            "heading": "United States of America",
            "subheading": "Nanny, Governess & Nursing Agency",
            "flag": imgRelativePath + "/country_flags/america.jpg"
        },
        {
            "heading": "China",
            "subheading": "Nanny, Governess & Nursing Agency",
            "flag": imgRelativePath + "/country_flags/china.jpg"
        }
    ]

    const services = [
        {
            "heading": "Maternity Nurses",
            "sentence": "With the support and care of an experienced maternity nurse (often known as a baby nurse), new parents \n" +
                "will gain confidence during those all-important first few months.",
        },
        {
            "heading": "Nannies",
            "sentence": "Our professional and exceptionally experienced nannies become a seamless part of the family, ensuring \n" +
                "the well-being and safety of the children is always paramount. \n\n" +
                "We understand that the needs of every family are different, and we are proud to work with compassionate \n" +
                "and talented live-in nannies, live-out nannies, rota nannies, bilingual nannies and travelling nannies.",
        },
        {
            "heading": "Governess",
            "sentence": "Our governess candidates are qualified professionals (often teachers, psychologist) employed by our \n" +
                "clients’ families to educate their children in their own homes. Governesses are also able to provide \n" +
                "supplementary learning and support in an after-school capacity.\n\n" +
                "Bright Minder UK is a leading London agency providing experienced governesses to private households in \n" +
                "London and International.",
        },
        {
            "heading": "Additional Services",
            "sentence": "Drawing on our extensive knowledge and experience, Bright Minder delivers training packages to a range \n" +
                "of clients both in the UK and overseas. Our training packages offer exceptional value for money.",
        }
    ]

    return (
        <div className="services-content-container">
            <div className="services-main-content video">
                <video autoPlay loop muted>
                    <source src={vidRelativePath + "people_and_workforce_video.mp4"} type="video/mp4"/>
                </video>
            </div>
            <div className="services-info">
                <h1>People and Workforce<span className="bColor">_</span></h1>
                <p>
                    Welcome to Bright Minder UK, Nanny, Governess & Nursing Agency specialising in the placement of
                    experienced and suitably qualified Nannies, Governesses & Nurses in the UK, and International.
                </p>
                <p>
                    We have a strong focus on diversity, inclusivity, and cross-cultural communication. Matching our clients
                    with professionals that can adjust to the local and international environment.
                </p>
            </div>
            <div className="services-section">
                <div className="services-content-section">
                    <p style={{width: '75%', marginBottom: '30px'}} className={"mx-auto"}>
                        As a Specialist Agency, we serve Clients ranging from Ultra-Ultra High Net Worth (UUHNW)
                        families,
                        International Royal households, Corporations, Government Bodies.
                    </p>
                    <h5 className="w-75 mx-auto text-bg-primary">Our comprehensive early years expertise includes:</h5>
                    <div className="card-deck people-card-deck mx-auto">
                        {
                            people_and_workforce_cards.map(
                                (item) => {
                                    return (
                                        <div className="card people-cards" key={item.heading}>
                                            <div
                                                className="card-content position-relative top-50 start-50 translate-middle p-5">
                                                <h4 className="card-heading">
                                                    {item.heading}
                                                </h4>
                                            </div>
                                        </div>
                                    )
                                }
                            )
                        }
                    </div>
                    <p style={{width: '75%'}} className={"mx-auto"}>
                        We recognise that each client and its aims are unique and that the ways of working will reflect
                        the clients’
                        beliefs, values, and culture. We are energised by identifying the synergy that binds us together
                        with the
                        client we work with, by developing clients through their people and by contributing to overall
                        quality and
                        success.
                    </p>
                    <p style={{width: '75%'}} className={"mx-auto"}>
                        Our child-centred approach means that we can help any client that works with or that has
                        customers who
                        are children or families. We can make suggestions around where improvements, in the best
                        interests of
                        these children and families, might be made.
                    </p>
                    <h5 style={{width: '75%', marginTop: '30px'}} className={"mx-auto"}>
                        We offer services in the following countries:
                    </h5>
                    <div className="main mx-auto">
                        <ul>
                            {
                                country_flags_and_details.map(item => {
                                    return <li>{item.heading} <img src={item.flag} width="30px" style={{marginLeft: "10px"}} alt=""/></li>
                                })
                            }
                        </ul>
                        {/*<div className="dynamic">*/}
                        {/*    <div className="countries-image"*/}
                        {/*         style={{backgroundImage: "url(" + country_flags_and_details[0].flag + ")"}}></div>*/}
                        {/*    <div className="countries-overlay">*/}
                        {/*        <h6>{country_flags_and_details[0].heading}</h6>*/}
                        {/*        <p>{country_flags_and_details[0].subheading}</p>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className="dynamic">*/}
                        {/*    <div className="countries-image"*/}
                        {/*         style={{backgroundImage: "url(" + country_flags_and_details[1].flag + ")"}}></div>*/}
                        {/*    <div className="countries-overlay">*/}
                        {/*        <h6>{country_flags_and_details[1].heading}</h6>*/}
                        {/*        <p>{country_flags_and_details[1].subheading}</p>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                    {/*<div className="main mx-auto">*/}
                    {/*    <div className="dynamic">*/}
                    {/*        <div className="countries-image"*/}
                    {/*             style={{backgroundImage: "url(" + country_flags_and_details[2].flag + ")"}}></div>*/}
                    {/*        <div className="countries-overlay">*/}
                    {/*            <h6>{country_flags_and_details[2].heading}</h6>*/}
                    {/*            <p>{country_flags_and_details[2].subheading}</p>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    <div className="dynamic">*/}
                    {/*        <div className="countries-image"*/}
                    {/*             style={{backgroundImage: "url(" + country_flags_and_details[3].flag + ")"}}></div>*/}
                    {/*        <div className="countries-overlay">*/}
                    {/*            <h6>{country_flags_and_details[3].heading}</h6>*/}
                    {/*            <p>{country_flags_and_details[3].subheading}</p>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className="main mx-auto">*/}
                    {/*    <div className="dynamic">*/}
                    {/*        <div className="countries-image"*/}
                    {/*             style={{backgroundImage: "url(" + country_flags_and_details[4].flag + ")"}}></div>*/}
                    {/*        <div className="countries-overlay">*/}
                    {/*            <h6>{country_flags_and_details[4].heading}</h6>*/}
                    {/*            <p>{country_flags_and_details[4].subheading}</p>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    <div className="dynamic">*/}
                    {/*        <div className="countries-image"*/}
                    {/*             style={{backgroundImage: "url(" + country_flags_and_details[5].flag + ")"}}></div>*/}
                    {/*        <div className="countries-overlay">*/}
                    {/*            <h6>{country_flags_and_details[5].heading}</h6>*/}
                    {/*            <p>{country_flags_and_details[5].subheading}</p>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className="main mx-auto">*/}
                    {/*    <div className="dynamic">*/}
                    {/*        <div className="countries-image"*/}
                    {/*             style={{backgroundImage: "url(" + country_flags_and_details[6].flag + ")"}}></div>*/}
                    {/*        <div className="countries-overlay">*/}
                    {/*            <h6>{country_flags_and_details[6].heading}</h6>*/}
                    {/*            <p>{country_flags_and_details[6].subheading}</p>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    <div className="dynamic" style={{zIndex: -10000000}}></div>*/}
                    {/*</div>*/}
                    <p style={{width: '75%'}} className={"mx-auto"}>
                        Our bespoke agency can really make the difference by collaborating with you, giving you a
                        distinct
                        competitive advantage and the Bright Minder mark of excellence that was born from its Business
                        Consultancy roots.
                    </p>
                    {
                        services.map(
                            (item) => {
                                return (
                                    <React.Fragment>
                                        <h4 className="w-75 mx-auto text-bg-primary">
                                            {item.heading}
                                        </h4>
                                        <p style={{width: '75%', marginBottom: '30px    '}} className={"mx-auto"}>
                                            {item.sentence}
                                        </p>
                                    </React.Fragment>
                                )
                            }
                        )
                    }
                </div>
            </div>
        </div>
    );
}

export default PeopleAndWorkforce;