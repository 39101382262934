import React from "react";
import {Link} from "react-router-dom";
import ImageCarousel from "../ImageCarousel/ImageCarousel";

const RecruitmentNav = () => {
    const insightsNavOptions = [
        {
            heading: "Client Registration",
            url: "/client-registration"
        },
        {
            heading: "Candidate Registration",
            url: "/candidate-registration"
        },
        {
            heading: "Client Application Journey",
            url: "/client-journey"
        },
        {
            heading: "Candidate Application Journey",
            url: "/candidate-journey"
        }
    ];

    return (
        <div className="custom-dropdown-menu-item">
            {/*<div className="custom-dropdown-menu-item-left">*/}
            {/*    <h3 className="dropdown-menu-item-heading">Recruitment</h3>*/}
            {/*    <p className="dropdown-menu-item-text">*/}
            {/*        Asking the better questions that unlock new answers to the working world's most complex issues.*/}
            {/*    </p>*/}
            {/*    <Link to="/recruitment" className="dropdown-menu-item-btn">Explore</Link>*/}
            {/*</div>*/}
            <div className="custom-dropdown-menu-item-middle">
                <ul className="dropdown-menu-item-context">
                    {
                        insightsNavOptions.map(item => {
                            return (
                                <li className="dropdown-menu-item-context-item">
                                    <Link to={item.url} className="insights-link">{item.heading}</Link>
                                    <i className="fa-solid fa-arrow-right"></i>
                                </li>
                            );
                        })
                    }
                </ul>
            </div>
            <div className="custom-dropdown-menu-item-right">
                <div className={"position-relative top-50 start-50 translate-middle w-50"}>
                    {/*<ImageCarousel/>*/}
                </div>
            </div>
            <div className="custom-dropdown-menu-item-left">
                {/*<h3 className="dropdown-menu-item-heading">Recruitment</h3>*/}
                {/*<p className="dropdown-menu-item-text">*/}
                {/*    Asking the better questions that unlock new answers to the working world's most complex issues.*/}
                {/*</p>*/}
                {/*<Link to="/recruitment" className="dropdown-menu-item-btn">Explore</Link>*/}
            </div>
        </div>
    )
}

export default RecruitmentNav;