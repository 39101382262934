import React, { useEffect } from "react";
import {Route, Switch, useLocation} from "react-router-dom";
import particlesJS from 'particles.js';
import './App.css';

import Homepage from "./homepage/Homepage";
import SideNavbar from "./SocialMediaNavbar/SideNavbar";
import Navbar from "./Navbar/Navbar";
import ContactPage from "./ContactPage/Contact";
import AboutPage from "./AboutPage/AboutPage";
import Recruitment from "./Recruitment/Recruitment";
import Candidate from "./Recruitment/Candidate/Candidate";
import Client from "./Recruitment/Client/Client";
import Services from "./Services/Services";
import PeopleAndWorkforce from "./Services/PeopleAndWorkforce/PeopleAndWorkforce";
import NotFound from './NotFound/NotFound';
import EngagementFees from "./Services/EngagementFees/EngagementFees";
import FAQ from "./FaqPage/FAQ";
import ClientJourney from "./Journeys/ClientJourney";
import CandidateJourney from "./Journeys/CandidateJourney";
import Insights from "./Insights/Insights";
import Consultancy from "./Consultancy/Consultancy";
import Login from "./Login/Login";

const App = () => {

    // useEffect(() => {
    //             window.particlesJS('particles-js',
    //                 {
    //                     "particles": {
    //                         "number": {
    //                             "value": 60,
    //                             "density": {
    //                                 "enable": true,
    //                                 "value_area": 900
    //                             }
    //                         },
    //                         "color": {
    //                             "value": "#dc143c"
    //                         },
    //                         "shape": {
    //                             "type": "circle",
    //                             "stroke": {
    //                                 "width": 0,
    //                                 "color": "#000000"
    //                             },
    //                             "polygon": {
    //                                 "nb_sides": 5
    //                             },
    //                             "image": {
    //                                 "src": "img/github.svg",
    //                                 "width": 100,
    //                                 "height": 100
    //                             }
    //                         },
    //                         "opacity": {
    //                             "value": 0.5,
    //                             "random": true,
    //                             "anim": {
    //                                 "enable": true,
    //                                 "speed": 1.5,
    //                                 "opacity_min": 0,
    //                                 "sync": false
    //                             }
    //                         },
    //                         "size": {
    //                             "value": 4,
    //                             "random": true,
    //                             "anim": {
    //                                 "enable": false,
    //                                 "speed": 6,
    //                                 "size_min": 0.3,
    //                                 "sync": false
    //                             }
    //                         },
    //                         "line_linked": {
    //                             "enable": false,
    //                             "distance": 150,
    //                             "color": "#ffffff",
    //                             "opacity": 0.4,
    //                             "width": 1
    //                         },
    //                         "move": {
    //                             "enable": true,
    //                             "speed": 2,
    //                             "direction": "none",
    //                             "random": true,
    //                             "straight": false,
    //                             "out_mode": "out",
    //                             "bounce": false,
    //                             "attract": {
    //                                 "enable": false,
    //                                 "rotateX": 600,
    //                                 "rotateY": 600
    //                             }
    //                         }
    //                     },
    //                     "interactivity": {
    //                         "detect_on": "canvas",
    //                         "events": {
    //                             "onhover": {
    //                                 "enable": true,
    //                                 "mode": "bubble"
    //                             },
    //                             "onclick": {
    //                                 "enable": true,
    //                                 "mode": "repulse"
    //                             },
    //                             "resize": true
    //                         },
    //                         "modes": {
    //                             "grab": {
    //                                 "distance": 400,
    //                                 "line_linked": {
    //                                     "opacity": 1
    //                                 }
    //                             },
    //                             "bubble": {
    //                                 "distance": 250,
    //                                 "size": 0,
    //                                 "duration": 2,
    //                                 "opacity": 0,
    //                                 "speed": 4
    //                             },
    //                             "repulse": {
    //                                 "distance": 400,
    //                                 "duration": 0.4
    //                             },
    //                             "push": {
    //                                 "particles_nb": 4
    //                             },
    //                             "remove": {
    //                                 "particles_nb": 2
    //                             }
    //                         }
    //                     },
    //                     "retina_detect": true
    //                 }
    //             );
    //
    //             // Cleanup function to clear the particles when the component is unmounted
    //             return () => {
    //                 if (window.pJSDom) {
    //                     window.pJSDom[0].pJS.fn.vendors.destroypJS();
    //                 }
    //             };
    //         }, []);

    const location = useLocation();

    // Check if the current location is the login page
    const isLoginPage = location.pathname === '/portal/login';

    return (
    <React.Fragment>
        {/*<div id="particles-js"></div>*/}
        <div className="main-content">
            {!isLoginPage && <Navbar/>}
            <Switch>
                <Route exact path="/" component={Homepage}/>
                {/*<Route exact path="/insights" component={Insights}/>*/}
                <Route exact path="/services" component={Services}/>
                <Route exact path="/people-and-workforce" component={PeopleAndWorkforce}/>
                {/*<Route exact path="/engagement-fees" component={EngagementFees}/>*/}
                <Route path="/about" component={AboutPage}/>
                <Route path="/recruitment" component={Recruitment}/>
                <Route path="/client-journey" component={ClientJourney}/>
                <Route path="/candidate-journey" component={CandidateJourney}/>
                <Route path="/candidate-registration" component={Candidate}/>
                <Route path="/client-registration" component={Client}/>
                <Route path="/contact" component={ContactPage}/>
                <Route path="/consultancy" component={Consultancy}/>
                {/*<Route path="/faq" component={FAQ}/>*/}
                {/*<Route path="/portal/login" component={Login}/>*/}
                <Route component={NotFound} />
            </Switch>
            <SideNavbar/>
            {/*<Footer/>*/}
        </div>
    </React.Fragment>
    );
}

export default App;
