import React from "react";
import './Candidate.css'
import {Link} from "react-router-dom";

const Candidate = () => {
    return (
        <div className="recruit-content-container">
            <div className="recruit-main-content"></div>
            <div className="recruit-info w-50  position-absolute start-50 top-0 translate-middle-x">
                <h1><span className="bColor">_</span>Register With Us</h1>
                <p>
                    We understand that every family is different, every child has individual needs, and every candidate
                    can bring different strengths into the home. We have over 30 years’ experience in placing maternity
                    nurses, permanent nannies, and governesses across London and worldwide with a wide selection of
                    welcoming families.
                </p>
                <p>
                    Create your family profile with us, and one of our consultants will be in touch to understand
                    exactly
                    what you’re looking for for your children, and how we can help. We work tirelessly to ensure the
                    perfect match, and are happy to answer any questions you might have, or discuss any uncertainties.
                </p>
                <div className="about-buttons">
                    <Link to="/candidate-journey" className="about-btn">View Journey</Link>
                </div>
            </div>
            <div id="recruitment" className="candidate-section">
                <h1 className="recruit-heading p-2">
                    Candidate Form<span className="bColor">_</span>
                </h1>
                <div className="recruitment-section">
                    <div className="candidate-info">
                        <div className="candidate-container mx-auto">
                            <div className="row input-container">
                                <div className="col-md-6 col-sm-2">
                                    <div className="styled-input wide">
                                        <input type="text" required />
                                        <label>First Name</label>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-2">
                                    <div className="styled-input wide">
                                        <input type="text" required />
                                        <label>Last Name</label>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-2">
                                    <div className="styled-input">
                                        <input type="text" required />
                                        <label>Date of Birth</label>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="styled-input" style={{float: "right"}}>
                                        <input type="text" required />
                                        <label>Nationality (or Nationalities)</label>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-2">
                                    <div className="styled-input">
                                        <input type="text" required />
                                        <label>Language(s)</label>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="styled-input" style={{float: "right"}}>
                                        <input type="text" required />
                                        <label>Where do you currently live?</label>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-2">
                                    <div className="styled-input">
                                        <input type="text" required />
                                        <label>Email Address</label>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="styled-input" style={{float: "right"}}>
                                        <input type="text" required />
                                        <label>Phone Number</label>
                                    </div>
                                </div>
                                <div className="col-xs-12" style={{height: 20}}>
                                </div>
                                <div className="col-md-6 col-sm-12 ">
                                    <label>What kind of role would you like to be considered for?</label>
                                    <div className="styled-input wide">
                                        <select name="roles" id="roles" className="select-field">
                                            <option value="Maternity Nurse">Maternity Nurse</option>
                                            <option value="Nanny">Nanny</option>
                                            <option value="Governance">Governance</option>
                                            <option value="Nanny Tutor">Nanny Tutor</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <label>Would you like to be considered for a specific condition?</label>
                                    <div className="styled-input wide">
                                        <div style={{display: "inline-block"}}>
                                            <input type="checkbox" id="specific-position-yes" name="specific-position-yes" value="specific-position-yes" width={20} style={{backgroundColor: "chartreuse"}}/>Yes
                                        </div>
                                        <br/>
                                        <input type="checkbox" id="specific-position-no" name="specific-position-no" value="specific-position-no" />No
                                    </div>
                                </div>
                                <div className="col-xs-12">
                                    <div className="styled-input wide">
                                        <input type="text" required />
                                        <label>Please include the reference(s) of the position(s) you are interested in:</label>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <label style={{marginTop: 15,marginBottom: 0, paddingBottom: 0}}>Where would you like to work?</label>
                                    <div className="styled-input wide">
                                        <select name="roles" id="roles" className="select-field">
                                            <option value="London">London</option>
                                            <option value="Europe">Europe</option>
                                            <option value="Middle East">Middle East</option>
                                            <option value="Other">Other</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-xs-12">
                                    <div className="styled-input wide">
                                        <input type="text" required />
                                        <label>Please list the locations where you are able to work:</label>
                                    </div>
                                </div>
                                <div className="col-xs-12">
                                    <div className="styled-input wide">
                                        <input type="text" required />
                                        <label>How many years of experience do you have?</label>
                                    </div>
                                </div>
                                <div className="col-xs-12">
                                    <div className="styled-input wide">
                                        <textarea required></textarea>
                                        <label className="textarea">Include any relevant information that we can't find on your CV!</label>
                                    </div>
                                </div>
                                <div className="col-md-10 col-sm-12 py-2">
                                    <input type="checkbox" id="consent" name="consent" value="consent" style={{marginBottom: "20px"}}/>
                                    <label style={{width: "90%"}}>
                                        I consent to my submitted data being collected and stored to allow
                                        Bright Minder to deal with my enquiry. For information about how we will use and
                                        protect your personal data please view our <Link to="#">privacy policy</Link>.
                                    </label>
                                </div>
                                <div className="col-xs-12">
                                    <div className="btn-lrg submit-btn">Send Message</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Candidate;