import React from 'react';
import './AboutPage.css';
import {Link} from "react-router-dom";

const AboutPage = () => {
    const imgRelativePath = `${process.env.PUBLIC_URL}/Assets/Images/`
    return (
        <div className="about-content-container">
            {/*<div className="about-main-content"></div>*/}
            {/*<div className="about-info w-50 position-relative start-50 top-50 translate-middle" style={{top: "-600px"}}>*/}
            {/*    <h1 >About Us</h1>*/}
            {/*    /!*<p>*!/*/}
            {/*    /!*    We are a leading recruitment agency with a proven track record of success. We help businesses find*!/*/}
            {/*    /!*    the right talent to meet their needs, from entry-level to executive positions. We have a deep*!/*/}
            {/*    /!*    understanding of the industries we serve and a wide network of qualified candidates.*!/*/}
            {/*    /!*</p>*!/*/}
            {/*    /!*<p>*!/*/}
            {/*    /!*    We are passionate about helping our clients achieve their recruitment goals, and we work closely*!/*/}
            {/*    /!*    with them to understand their needs and develop a customized recruitment strategy.*!/*/}
            {/*    /!*</p>*!/*/}
            {/*    /!*<div className="about-buttons">*!/*/}
            {/*    /!*    <Link to="/client-registration" className="about-btn">Register As A Client</Link>*!/*/}
            {/*    /!*    <Link to="/candidate-registration" className="about-btn">Register As A Candidate</Link>*!/*/}
            {/*    /!*</div>*!/*/}
            {/*</div>*/}
            <div id="how-we-work" className="text-center-about-body">
                <h1 className="text-center-about-us">
                    About Us
                </h1>
            </div>
            {/*<div id="how-we-work" className="text-center-about-us">*/}
            {/*    <h1 className="text-center-about-us how-we-work-heading p-2">*/}
            {/*        About Us_*/}
            {/*    </h1>*/}
            {/*</div>*/}
            {/*    <div className="how-we-work-section mx-auto">*/}
            {/*        <div className="how-we-work-section-info">*/}
            {/*            <div className="how-we-work-section-info-content w-75 position-relative top-50 start-50 translate-middle">*/}
            {/*                <h3>We interview</h3>*/}
            {/*                <p>*/}
            {/*                    We conduct in-depth interviews with each candidate and carefully match the profiles of*/}
            {/*                    clients with the profiles of maternity nurses, nannies and governesses.*/}
            {/*                </p>*/}
            {/*                <p>*/}
            {/*                    Every candidate is interviewed by one of our experienced consultants in our offices or via*/}
            {/*                    video call. Maternity nurses, nannies and governesses are asked to complete an application*/}
            {/*                    form providing their personal details and information about their experience,*/}
            {/*                    qualifications and employment history.*/}
            {/*                </p>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <div className="how-we-work-section-graphic">*/}
            {/*            <div className="border-box position-relative top-50 start-50 translate-middle">*/}
            {/*                <div className="border-box-inner position-relative top-75 start-75 translate-middle"></div>*/}
            {/*                <img src={imgRelativePath+`/AboutPage/about_page_interview.png`} className="position-relative top-50 start-50 translate-middle" alt="Interview Icon"/>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    <div className="how-we-work-section mx-auto">*/}
            {/*        <div className="how-we-work-section-graphic">*/}
            {/*            <div className="border-box position-relative top-50 start-50 translate-middle">*/}
            {/*                <div className="border-box-inner position-relative top-75 start-75 translate-middle"></div>*/}
            {/*                <img src={imgRelativePath+`/AboutPage/about_page_authenticity.png`} className="position-relative top-50 start-50 translate-middle" alt="We Check Icon"/>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <div className="how-we-work-section-info">*/}
            {/*            <div className="how-we-work-section-info-content w-75 position-relative top-50 start-50 translate-middle">*/}
            {/*                <h3>We Check</h3>*/}
            {/*                <p>*/}
            {/*                    We understand that bringing someone into your family isn’t a decision taken lightly,*/}
            {/*                    and so we conduct a thorough process, checking and verifying;*/}
            {/*                </p>*/}
            {/*                <ul>*/}
            {/*                    <li>*/}
            {/*                        the identity of each candidate against their passport or driving licence*/}
            {/*                    </li>*/}
            {/*                    <li>*/}
            {/*                        the reasons for any gaps in their employment history*/}
            {/*                    </li>*/}
            {/*                    <li>*/}
            {/*                        the copy of their certificates or qualifications*/}
            {/*                    </li>*/}
            {/*                    <li>*/}
            {/*                        the validity of Criminal Record Checks (DBS)*/}
            {/*                    </li>*/}
            {/*                    <li>*/}
            {/*                        at least two references to confirm the competence and suitability of each candidate*/}
            {/*                    </li>*/}
            {/*                </ul>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    <div className="how-we-work-section mx-auto">*/}
            {/*        <div className="how-we-work-section-info">*/}
            {/*            <div className="how-we-work-section-info-content w-75 position-relative top-50 start-50 translate-middle">*/}
            {/*                <h3>We stay connected</h3>*/}
            {/*                <p>*/}
            {/*                    We put forward maternity nurses, nannies, and governesses only for positions for which*/}
            {/*                    they appear to be suited by their qualifications, experience and personality. We provide*/}
            {/*                    full and relevant information to both prospective employers and candidates before*/}
            {/*                    arranging any interviews. We respect the confidentiality of our clients.*/}
            {/*                </p>*/}
            {/*                <p>*/}
            {/*                    We provide our clients with detailed CVs and references of suitable candidates. We*/}
            {/*                    liaise between clients and candidates to arrange a video interview, and when possible a*/}
            {/*                    personal interview of the candidate at your family home.*/}
            {/*                </p>*/}
            {/*                <p>*/}
            {/*                    Once a placement is secured, we provide assistance with travel arrangements, medical*/}
            {/*                    insurance and contract of employment. We offer a generous 8 week guarantee.*/}
            {/*                </p>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <div className="how-we-work-section-graphic">*/}
            {/*            <div className="border-box position-relative top-50 start-50 translate-middle">*/}
            {/*                <div className="border-box-inner position-relative top-75 start-75 translate-middle"></div>*/}
            {/*                <img src={imgRelativePath+`/AboutPage/about_page_stay_connected.png`} className="position-relative top-50 start-50 translate-middle" alt="We Stay Connected Icon"/>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    )
}

export default AboutPage;