import React from 'react';
import './Journey.css'
import {Link} from "react-router-dom";

const ClientJourney = () => {
    const client_journey = [
        {
            key: "candidate-journey",
            content: {
                p1: "Candidate registers with the agency (insert link to Candidate registration).",
                p2: "Candidate signs and returns the agency registration document (once).",
                p3: "Candidate and Bright Minder Consultant discuss background and key motivations.",
                p4: "Candidate provides a CV without any e-mail or telephone number within the document.",
                p5: "Candidate provides supporting documents when prompted (Certificates etc.).",
                p6: "Candidate expresses interest in role/s.",
                p7: "Seven days after application submission, consider the application unsuccessful.",
                p8: "If shortlisted, schedules are checked, an interview arranged.",
                p9: "Second interview is arranged upon request if prompted.",
                p10: "Unsuccessful candidates at interview stage, informed where possible.",
                p11: "Successful candidate issued with a notification of pending offer of employment.",
                p12: "Offer letter is sent to Candidate for review and signed acceptance.",
                p13: "Upon signed acceptance by both candidate and client, personal details are shared for the first time between client and candidate.",
                p14: "Employment Terms and Conditions are discussed between Candidate and Client for final acceptance.",
                p15: "Due diligence of the client may also be performed at candidates’ discretion.",
                p16: "Visa, Flights, Medical is arranged by the Client for International placements.",
                p17: "Client may perform additional background checks, including social media intelligence.",
                p18: "Candidate starts employment.",
                p19: "Candidate informs Bright Minder when probation period expires or any issues within key period.",
                p20: "Prior to contract expiry date, Candidate informs Bright Minder of contract extension or expiry.",
                p21: "Within the period of performance, Bright Minder will be informed if early contract termination.",
                p22: "Stay connected."
            }
        }
    ]

    return (
        <div className="body">
            <div className="main-box">
                <h4>Client Journey</h4>
                <p>This is the order in which an application will be completed:</p>
                <div className="updates-wrapper">
                    <div className="line"></div>
                    {
                        client_journey.map(item => {
                            const paraList = Object.entries(item.content).map(item_content=> {
                                const [key, value] = item_content
                                return <div className="update">
                                    <p>{value}</p>
                                    <span className="circle"></span>
                                </div>
                            })

                            return (
                                <div className="updates" key={item.key}>
                                    {
                                        paraList
                                    }
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        </div>
    );
};

export default ClientJourney;