import React from 'react';
import './Journey.css'
import {Link} from "react-router-dom";

const CandidateJourney = () => {
    const candidate_journey = [
        {
            key: "candidate-journey",
            content: {
                p1: `Client registers the vacancy defining the requirement ${<Link to={"/candidate-registration"}>R<span className="bColor">e</span>gister Here<span className="bColor">_</span></Link>}.`,
                p2: "Client is sent the Bright Minder Terms and Conditions document for signature (insert link to terms and conditions).",
                p3: "Client returns the signs terms and conditions document.",
                p4: "Bright Minder performs due diligence of the client.",
                p5: "Bright Minder Consultant reviews the requirement and finalises the scope with the client.",
                p6: "Bright Minder Consultant reviews our data base for any suitable an available match.",
                p7: "If no suitable match on database Bright Minder launches advertisement to attract candidates.",
                p8: "Bright Minder evaluates suitable candidates and shortlists potential matches.",
                p9: "Bright Minder conducts interviews carefully matching the profiles of the clients.",
                p10: "Client and Bright Minder agree on the number of candidates to be interviewed.",
                p11: `Bright Minder performs background checks: ${<ul>
                    <li>Identity</li>
                    <li>Employment history</li>
                    <li>Qualification</li>
                    <li> Criminal Record Checks (DBS)</li>
                    <li>Two references</li>
                    <li>Social Media check</li>
                    <li>Additional checks performed for international placements</li>
                </ul>}`,
                p12: "Interviews arranged between Client & Candidate.",
                p13: "Client and candidate are not permitted to exchange details at this stage.",
                p14: "Second interview arranged between Client & Candidate (upon request).",
                p15: "Client notifies Bright Minder of candidate selection.",
                p16: "Upon acceptance and signature, Bright Minder returns signed Employment Offer.",
                p17: "Bright Minder provides personal details of the Candidate to the Client.",
                p18: "Client arranges visa, flights etc. directly with the selected candidate (International placements).",
                p19: "Client may opt to perform additional background checks prior to commencing engagement.",
                p20: "Engagement fee is paid by the client before candidate commences employment.",
                p21: "Client and Candidate agree the Employment contract (T&Cs) that may differ per region.",
                p22: "Client confirms start date with agency and the candidate.",
                p23: "Client informs Bright Minder when selected candidate’s probation period expires.",
                p24: "Prior to contract expiry, client informs Bright Minder of contract renewal or expiry.",
                p25: "Contract extension is subject to new engagement fee, Bright Minder issues new invoice.",
                p26: "No probation period when contract is renewed.",
                p27: "Stay connected."
            }
        }
    ]

    return (
        <div className="body">
            {/*<div className="colored-box"></div>*/}
            <div className="main-box">
                <h4>Candidate Journey</h4>
                <p>This is the order in which an application will be completed:</p>
                <div className="updates-wrapper">
                    <div className="line"></div>
                    {
                        candidate_journey.map(item => {
                            const paraList = Object.entries(item.content).map(item_content=> {
                                const [key, value] = item_content
                                // return <p key="timeline">{value}</p>
                                return <div className="update">
                                            <p>{value}</p>
                                            <span className="circle"></span>
                                        </div>
                            })

                           return (
                                <div className="updates" key={item.key}>
                                    {
                                        paraList
                                    }
                                </div>
                           );
                        })
                    }
                </div>
            </div>
        </div>
    );
};

export default CandidateJourney;