import React, {useState, useEffect, useMemo} from 'react';
import './ImageSlider.css';

const ImageSlider = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const imgRelativePath = `${process.env.PUBLIC_URL}/Assets/Images/`

    // const sliderData = useMemo(() => [
    //     {
    //         heading: "Most of our clients come from personal recommendations",
    //         sentence: "Parents and nannies come to us because of our reputation. They stay because we genuinely care.",
    //         imageName:  `${imgRelativePath}image1.jpg`,
    //     },
    //     {
    //         heading: "We place experienced Maternity Nurses, Nannies and Governesses worldwide",
    //         sentence: "We take a tailor-made approach to each placement, ensuring you only meet candidates who would be a perfect fit for your family.",
    //         imageName:  `${imgRelativePath}image2.jpg`,
    //     },
    //     {
    //         heading: "Nannies Incorporated are head and shoulders above the rest.",
    //         sentence: "We’re proud to be so highly thought of. We work hard every day to deserve it.",
    //         imageName:  `${imgRelativePath}image3.jpg`,
    //     },
    //     {
    //         heading: "Get in touch",
    //         sentence: "We are reassuringly professional and friendly, too. Let’s have a chat and discuss how we can help you.",
    //         imageName:  `${imgRelativePath}image4.jpg`,
    //     }
    // ], [imgRelativePath]);

    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         setCurrentSlide((currentSlide + 1) % sliderData.length);
    //     }, 5000);
    //
    //     return () => clearInterval(interval);
    // }, [sliderData, currentSlide]);
    //
    // const handleDotClick = (index) => {
    //     setCurrentSlide(index);
    // };


    return (
        <div className="slideshow">
            <img
                // src={sliderData[currentSlide].imageName}
                src={imgRelativePath + "Kids/Full_Image_Bright Minds.png"}
                alt="Slideshow image"
                className="slideshow-image"
            />
            {/*<div className="slideshow-text wording">*/}
            {/*    <h1 className="heading">*/}
            {/*        {sliderData[currentSlide].heading}*/}
            {/*    </h1>*/}
            {/*    <p className="para-info">*/}
            {/*        {sliderData[currentSlide].sentence}*/}
            {/*    </p>*/}
            {/*    <a className="slideshow-text-btn">Hire With Us</a>*/}
            {/*</div>*/}
            {/*<div className="slideshow-dots">*/}
            {/*    {sliderData.map((image, index) => (*/}
            {/*        <div*/}
            {/*            key={index}*/}
            {/*            className={`slideshow-dot ${index === currentSlide && 'active'}`}*/}
            {/*            onClick={() => handleDotClick(index)}*/}
            {/*        />*/}
            {/*    ))}*/}
            {/*</div>*/}
        </div>
    );
};

export default ImageSlider;